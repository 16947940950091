export const menuItems = [
  {
    id: 1,
    label: "menuitems.dashboard.text",
    icon: "uil-home-alt",
    link: "/",
  },
  {
    id: 10,
    label: "User Management",
    icon: "uil-user",
    subItems: [
      {
        id: 11,
        label: "Add User",
        link: "/users/add",
        parentId: 10,
      },
      {
        id: 12,
        label: "User Listing",
        link: "/users/listing",
        parentId: 10,
      },
    ],
  },
  {
    id: 10,
    label: "Category",
    icon: "uil-apps",
    subItems: [
      {
        id: 11,
        label: "Add Category",
        link: "/category/add",
        parentId: 10,
      },
      {
        id: 12,
        label: "Category Listing",
        link: "/category/listing",
        parentId: 10,
      },
    ],
  },
  {
    id: 1122,
    label: "Sub Category",
    icon: "uil-dialpad-alt",
    subItems: [
      {
        id: 11,
        label: "Add Sub Category",
        link: "/sub-category/add",
        parentId: 1122,
      },
      {
        id: 12,
        label: "Sub Category Listing",
        link: "/sub-category/listing",
        parentId: 1122,
      },
    ],
  },
  {
    id: 2211,
    label: "Feature Management",
    icon: "uil-list-ui-alt",
    subItems: [
      {
        id: 22111,
        label: "Add Feature",
        link: "/feature/add",
        parentId: 2211,
      },
      {
        id: 22111,
        label: "Feature Listing",
        link: "/features/listing",
        parentId: 2211,
      },
    ]

  },
  {
    id: 20,
    label: "Business Directory",
    icon: "uil-bag-alt",
    subItems: [
      {
        id: 21,
        label: "Add Business",
        link: "/business/add",
        parentId: 20,
      },
      {
        id: 7,
        label: "Business Listing",
        link: "/business/listing",
        parentId: 20,
      },
      {
        id: 71,
        label: "Bulk Upload Business",
        link: "/csvupload",
        parentId: 20,
      },
    ],
  },
  {
    id: 30,
    label: "Claim Business",
    icon: "uil-document-layout-left",
    subItems: [
      {
        id: 31,
        label: "All claims",
        link: "/business/listing/claim",
        parentId: 30,
      },
      {
        id: 32,
        label: "Claim request",
        link: "/business/listing/request",
        parentId: 30,
      },
      {
        id: 33,
        label: "Approved Claims",
        link: "/business/listing/approved",
        parentId: 30,
      },
      {
        id: 34,
        label: "Rejected Claims",
        link: "/business/listing/rejected",
        parentId: 30,
      },
    ],
  },
  {
    id: 40,
    label: "Payments",
    icon: "uil-card-atm",
    subItems: [
      {
        id: 41,
        label: "Payment Listing",
        link: "/business/payments",
        parentId: 40
      }

    ]
  },
  {
    id: 50,
    label: "Reviews",
    icon: "uil-comment-alt-lines",
    link: "/business/reviews"
  },
  {
    id: 321,
    label: "Subscription Plans",
    icon: "uil-file-check-alt",
    link: "/subscriptions-plan"
  },


  // {
  //     id: 2,
  //     label: "Area & Role",
  //     icon: "uil-house-user",
  //     subItems: [
  //         {
  //             id: 1,
  //             label: "Create Area with Role",
  //             link: "/workarea/create",
  //             parentId: 2
  //         },
  //         {
  //             id: 2,
  //             label: "Listing",
  //             link: "/workarea/listing",
  //             parentId: 2
  //         },

  //     ]
  // },

  // {
  //     id:3,
  //     label: "Shift",
  //     icon: "mdi mdi-pencil-minus-outline",
  //     subItems: [
  //         {
  //             id: 1,
  //             label: "Create Shift",
  //             link: "/create-shift",
  //             parentId: 3
  //         },
  //         {
  //             id: 2,
  //             label: "Create Weekly Shift",
  //             link: "/create-weekly-shift",
  //             parentId: 3
  //         },
  //         {
  //             id: 3,
  //             label: "Shift Listing",
  //             link: "/shifts",
  //             parentId: 3
  //         },
  //         {
  //             id: 4,
  //             label: "Shift Requests",
  //             link: "/restaurant-owner-shift-listing",
  //             parentId: 3
  //         },
  //         {
  //             id:5,
  //             label: "Calendar",
  //             // icon: "uil-calender",
  //             link: "/calendar",
  //             parentId: 3
  //         },

  //         {
  //             id:6,
  //             label: "Shifts Roster",
  //             // icon: "uil-calender",
  //             link: "/shifts-roster",
  //             parentId: 3
  //         },

  //     ]
  // },
  // {
  //     id:4,
  //     label: "Team Members",
  //     icon: "uil-users-alt",
  //     subItems: [
  //         {
  //             id: 1,
  //             label: "New Requests",
  //             link: "/team-member-requests",
  //             parentId: 4
  //         },
  //         {
  //             id: 2,
  //             label: "All Active Members",
  //             link: "/team-member-record",
  //             parentId: 4
  //         },
  //         {
  //             id: 3,
  //             label: "Former Team Members",
  //             link: "/former-team-member-record",
  //             parentId: 4
  //         },
  //         {
  //             id: 4,
  //             label: "Add Members",
  //             link: "/create-team-member",
  //             parentId: 4
  //         },

  //     ]
  // },
  // {
  //     id: 5,
  //     label: "Manager Users ",
  //     icon: "mdi mdi-account-cog-outline",
  //     subItems: [
  //         {
  //             id: 1,
  //             label: "User Listing",
  //             link: "/managers-listing",
  //             parentId: 5
  //         },
  //         {
  //             id: 2,
  //             label: "Create Manager",
  //             link: "/create-manager",
  //             parentId: 5
  //         },

  //     ]
  // },
  // {
  //     id: 6,
  //     label: "Locations",
  //     icon: "mdi mdi-map-marker-outline",
  //     subItems: [
  //         {
  //             id: 14,
  //             label: "Location Listing",
  //             link: "/locations",
  //             parentId: 6
  //         },
  //         {
  //             id: 15,
  //             label: "Create Location",
  //             link: "/create-location",
  //             parentId: 6
  //         },

  //     ]
  // },
];