<script>
export default {
    
}
</script>

<template>
    <footer class="footer">
    <div class="container-fluid text-center">
        <div class="">
            <div class="text-dark-50">© tipshare {{ new Date().getFullYear()}} - All Rights Reserved</div>
            <!-- <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                    Crafted with
                    <i class="mdi mdi-heart text-danger"></i> by
                    <a href="https://themesbrand.com/" target="_blank" class="text-reset">Themesbrand</a>
                </div>
            </div> -->
        </div>
    </div>
</footer>
</template>